import React, { Component } from 'react';
import './AboutUs.css';
import Card from './Card/Card';
import LeftImageRow from './LeftImageRow/LeftImageRow';
import RightImageRow from './RightImageRow/RightImageRow';
import CardInfo from './CardInfo.json';
class AboutUs extends Component {
    constructor(props){
        super(props);
        this.state = {
          mobile_view: false
        };
      }

    handle_screen_resize = (e) => 
    {
             this.setState({mobile_view: window.innerWidth<540})
    }

    componentDidMount(){
        window.addEventListener('resize',this.handle_screen_resize);
    }
    
    componentWillUnmount(){
        window.removeEventListener('resize',this.handle_screen_resize);
    }

    render() { 
        
        return ( 
            <div className="AboutUs">
                <header className="header-banner" style={{backgroundImage:this.state.mobile_view || window.innerWidth<540 ?"url(/assets/AboutUs/banner-mobile.jpg)":"url(/assets/AboutUs/banner.jpg)"}}>
                    <section className="header-banner-content">
                        <h1>About Us</h1>
                    </section>
                </header>

                <section className="section1 center-text">
                    <h1>Who we are</h1>
                    <p>We are a passionate team of digital scientists united in a mission to enable our clients in their transformation journey. We bring together a rare mix of technology and analytics experience to help clients optimize their operations and delight their customers. We see ourselves as true partners - immersing ourselves in your business, working with you in uncovering and defining latent opportunities, and designing solutions that are scalable. We take pride in our work and experience joy in helping our clients find their digital edge.</p>
                </section>

                <section className="section2 white-text center-text">
                    <h1>What drives us</h1>
                    <div className="cards">
                        {CardInfo.map((card,index) => <Card
                                                key={index}
                                                image={card.image}
                                                text={card.text}
                                                title={card.title}/>)} 
                    </div>
                    
                </section>
            
                <section className="section3  center-text">
                    <header>
                        <h1>Leadership Team</h1>
                    </header>
                    <section>
                        <LeftImageRow 
                            image={"/assets/AboutUs/Website_Mukund.png"}
                            name="Mukund Raghunath"
                            role="CHIEF EXECUTIVE OFFICER"
                            text="Mukund sets strategy and business direction at Acies. Prior to co-founding Acies, Mukund was a founding employee of Mu Sigma which grew to be the largest pure play analytics company in the world. Mukund has played many roles managing large portfolios of client engagements across multiple geographies and industry verticals. He has been a trusted advisor to senior leadership of multiple Fortune 500 companies, helping them with their analytics strategy and execution. Mukund has an MBA (Honors) from the University of Chicago, Booth School of Business. Mukund is also an active angel investor and holds advisory/board positions in start-ups in the technology space. Outside of work, Mukund is an avid traveler, runner and golf enthusiast."
                        />
                        <RightImageRow 
                            image={"/assets/AboutUs/Website_Nanda.png"}
                            name="Nanda Rajasek"
                            role="CHIEF DELIVERY OFFICER"
                            text="With over two decades of experience in building IT solutions both in digitalizing and enabling digital transformation. Nanda brings his wealth of knowledge in Custom Enterprise Solutions, Enterprise Application Integration, Business Intelligence Solutions and Mobile Solutions. Before co-founding Acies, Nanda was the Global COO of Vernalis. Nanda’s expertise also includes Network Design and Management, Information Security, Designing SOC2 compliant IT policies and procedures. Prior to joining Vernalis, Nanda worked with Ernst & Young, P&O Nedlloyd. Nanda holds a Bachelors degree in Computer Science Engineering from Madras University. Nanda loves long drives and listening to good music."
                        />
                        <LeftImageRow 
                            image={"/assets/AboutUs/Website_Siddharth.png"}
                            name="Siddharth S Kumar"
                            role="CHIEF DESIGN OFFICER"
                            text="A senior leader in brand strategy and communication with over 20 years of experience, Siddharth is passionate about CX, business narratives and brand design. He was previously the Co-Founder and Chief Creative Officer of PixelKraft, a full services agency that grew to an Entertainment and Experience Tech company in association with Centroid Motion Capture, UK. Siddharth also wrote and directed India’s first micro web series. Siddharth holds a Master’s degree in Medical Microbiology and started out as a Senior Copywriter at Lowe Lintas, where he has worked with diverse campaigns on brands like Saint Gobain, Unilever, The Hindu, MK, GRT, Servion, Star Vijay, Amaron and Kone. He enjoys practising Taichi and the occasional game of badminton."
                        />
                    </section>
                       
                </section>
            </div>
         );
    }
}
 
export default AboutUs;
