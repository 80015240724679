import React, { Component } from 'react';
import './CaseStudy.css';
import case_studies from '../../contents/case_study.json'
import {Link} from 'react-router-dom';
import random_pick from '../../helpers/random_pick'

class CaseStudy extends Component {
  constructor(props){
    super(props);
    this.state = {
      case_study: case_studies[0],
      more_case_studies: [case_studies[1],case_studies[2],case_studies[3]],
    };
  }
  
  random_case_study()
  {
    var arr = []
    var output = random_pick(case_studies.length, 4)
    for(var i=0;i<output.length && arr.length<3 ;i++)
    {   
        if(case_studies[output[i]].end_point !== this.props.match.params.id)
        {
          arr.push(case_studies[output[i]])
        }
    }
    return arr
  }

  set_case_study_from_path()
  {
    let data=case_studies.find(x=>x.end_point === this.props.match.params.id);
    if(data !== undefined)
    {
      this.setState({case_study:data,more_case_studies:this.random_case_study()})
    }
  }

  // componentWillMount(){}
  componentDidMount(){
    this.set_case_study_from_path()
  }
  // componentWillUnmount(){}
  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) 
    {
      if(this.props.location.content !== undefined) 
      {
        this.setState({case_study:this.props.location.content.item,more_case_studies:this.random_case_study()}) 
      }
      else
      {
        this.set_case_study_from_path()
      }
    }
  }
  //{this.state.case_study.heading}
  render() {
    return (
      <div>
        <div className="case-study-banner">
          <div className="case-study-banner-1-left case-study-banner-left">
              <div>{this.state.case_study.title}</div>
          </div>
          <img src={this.state.case_study.image_2x} alt="" className="case-study-banner-1-right case-study-banner-right"></img>
        </div>
        <div className="case-study-banner">
        <div className="case-study-banner-left case-study-banner-left-content case-study-sub-title-left"></div>
          <div className="case-study-banner-right case-study-banner-right-content case-study-sub-title">{this.state.case_study.sub_title}</div>
        </div>
        <div className="case-study-banner">
          <div className="case-study-banner-left case-study-banner-left-content">Challenge</div>
          <div className="case-study-banner-right case-study-banner-right-content">{this.state.case_study.challenge}</div>
        </div>

        <div className="case-study-banner">
          <div className="case-study-banner-left case-study-banner-left-content">Approach</div>
          <div className="case-study-banner-right case-study-banner-right-content">{this.state.case_study.approach}</div>
        </div>

        <div className="case-study-banner">
          <div className="case-study-banner-left case-study-banner-left-content">Outcome</div>
          <div className="case-study-banner-right case-study-banner-right-content">{this.state.case_study.outcome}</div>
        </div>

        <div className="case-study-banner">
          <div className="case-study-banner-left case-study-banner-left-content"></div>
          <div className="case-study-banner-right case-study-banner-right-content case-study-banner-right-keyword">{this.state.case_study.keywords}</div>
        </div>

        <div className="case-study-page-grid">
              <div className="more-case-study">More Case Studies</div>
              <div className="service-row-4 more-case-study-content">
              {this.state.more_case_studies.map((item, methodIndex) => {
                    return( 
                        <Link to={{pathname:item.path, content:{item}}} key={methodIndex} className="service-row-4-content">
                            <img className="service-row-4-content-banner-image" src={item.image} alt=""/>
                            <div className="service-row-4-content-banner-name">{item.heading}</div>
                        </Link>
                    )
                    })}
              </div>
        </div>
      </div>
    );
  }
}

export default CaseStudy;