import React, { Component } from 'react';
import './ContactUs.css';
import { Form } from 'react-formio'

class ContactUs extends Component {
  constructor(props){
    super(props);
    this.state = {
      mobile_view: false
    };
  }

  handle_screen_resize = (e) => 
    {
      this.setState({mobile_view: window.innerWidth<540})
    }

  // componentWillMount(){}
  componentDidMount(){
    window.addEventListener('resize',this.handle_screen_resize);
  }
  componentWillUnmount(){
    window.removeEventListener('resize',this.handle_screen_resize);
  }

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  render() {
    return (
      <div className="contact-us">
        <header className="header-banner contact-us-banner-1" style={{backgroundImage:this.state.mobile_view || window.innerWidth<540 ?"url(/assets/Banners/contact-us-mobile.jpg)":"url(/assets/Banners/contact-us.jpg)"}}>
              <h1 className="contact-us-banner-1-heading">Contact</h1>
      </header>
        <div className="address">
          <div>
            <h3>United States</h3>
            <div>21791 N Tall Oaks Drive</div>
            <div>Kildeer IL 60047</div>
            <div>Ph: + 1(847) 769 3938</div>
          </div>
          <div>
            <h3>India</h3>
            <div>RMZ One Paramount</div>
            <div>110 Mount Poonamallee Rd</div>
            <div>Porur, Chennai 600116</div>
            <div>Tamil Nadu India</div>
          </div>
        </div>
        <div className="contact-us-main">
          <div className="google-map">
            <iframe title="Acies" src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15548.29029286694!2d80.1679161!3d13.0310504!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0xe212d5d97710e9c9!2sAcies%20Global!5e0!3m2!1sen!2sin!4v1618299586616!5m2!1sen!2sin" 
            width="600" height="450" style={{border:"0",width:"100%",height:"100%"}} allowfullscreen="" loading="lazy"></iframe>
          </div>
          <div className="contact-us-form">
              <div className="contact-us-form-header">Contact Us</div>
              <Form src="https://bknjczmggzmztpm.form.io/contactus" />
          </div>
        </div>
      </div>
    );
  }
}

export default ContactUs;