import React, { Component } from 'react';
import './Team.css';
import Reviews from '../../contents/reviews.json'

class Team extends Component {
  constructor() {
    super();
    this.state = {
      reviews : Reviews,
      activeReview : Reviews[Math.floor(Reviews.length / 2)],
      activeReviewIndex : Math.floor(Reviews.length / 2),
      mobile_view: false
    }
  }
  changeOrder = (index) => {
    let ind = index
    if(index === -1)
    {
      ind =  4
    }
    else{
    ind = Math.abs(index) % this.state.reviews.length;}
    
    this.setState({activeReviewIndex:ind});
    this.setState({activeReview:this.state.reviews[ind]});
    // let newReviews = this.getReviews();
    
  }
  getReviews = () => {
    let newReviews =  [...this.addleftReviews(),this.state.activeReview, ...this.addRightReviews()]
    //this.setState({reviews : newReviews})
    return newReviews;
  }
  addleftReviews = () => {
    let reviews = [];
    let fullReviews = this.state.reviews;
    if(this.state.activeReviewIndex === 1) {
      reviews = [fullReviews[fullReviews.length - 1], fullReviews[0]];
    }
    else if(this.state.activeReviewIndex === 0) {
      reviews = [fullReviews[fullReviews.length - 2], fullReviews[fullReviews.length - 1]];
    }
    else {
      reviews = [fullReviews[this.state.activeReviewIndex - 2],fullReviews[this.state.activeReviewIndex - 1]];
    }
    return reviews;
    
  }
  addRightReviews = () => {
    let reviews = [];
    let fullReviews = this.state.reviews;
    if(this.state.activeReviewIndex === fullReviews.length - 2) {
      reviews = [fullReviews[fullReviews.length - 1], fullReviews[0]];
    }
    else if(this.state.activeReviewIndex === fullReviews.length - 1) {
      reviews = [fullReviews[0], fullReviews[1]];
    }
    else {
      reviews = [fullReviews[this.state.activeReviewIndex + 1],fullReviews[this.state.activeReviewIndex + 2]];
    }
    return reviews;
  }

  handle_screen_resize = (e) => {
    if(window.innerWidth<540) 
        this.setState({mobile_view: true})
    else 
        this.setState({mobile_view: false})
    }

    componentDidMount(){
        window.addEventListener('resize',this.handle_screen_resize);
    }

    componentWillUnmount(){
        window.removeEventListener('resize',this.handle_screen_resize);
    }

  render() {
    return (
      <div className="Team">
        <header className="header-banner" style={{backgroundImage:this.state.mobile_view || window.innerWidth<540 ?"url(/assets/Team/banner-mobile.jpg)":"url(/assets/Team/banner.jpg)"}}>
            <section className="header-banner-content">
                  <h1 >Our people. <br style={window.innerWidth<960?{display:"block"}:{display:"none"}}/>Our philosophy.</h1>
                  <a href={"https://talent.aciesglobal.com/jobs/Careers"} target={"_blank"} rel="noreferrer"  className="team-button white-text margin-top-5 join-team-button">
                    Join our team
                  </a>
            </section>
        </header>

        <h2 className="margin-bottom-15 continuos-learning-header">Continuous learning</h2>
        <section className="team-row padding-3 team-section-2">
          <section className="team-row-left">
              <p><span style={{fontWeight:"bold"}}>“Give a man a fish, and you feed him for a day. Teach a man to fish, and you feed him for a lifetime.”</span><div>—Chinese proverb</div></p>
              <p>As technology and the world around us continues to evolve everyday, the only constant is change. To keep up in this rapidly shifting landscape, a growth mindset is the key to survival. We want our associates to not just survive, but thrive. We want our team to experience joy in the process of learning. Be it business concepts, home-grown process frameworks, new ML methodologies, the latest BI tools, or emerging technology platforms, our learning is centered around tools and skill sets that will enhance our ability to serve our clients.</p>
              <p>The education process at Acies takes all shapes and forms - from structured instructor led-sessions to self-learning modules to learning by teaching and doing. The curiosity to assimilate information and grow is an inherent part of the organizational DNA. We pride ourselves on being a learning organization - constantly providing space and opportunities for our associates to learn, unlearn, and relearn.</p>
          </section>
          <section className="team-row-right">
              <p className="margin-bottom-15"><span style={{fontWeight:"bold"}}>“Coming together is a beginning. Keeping together is progress. Working together is success.”</span><div>—Henry Ford</div></p> 
              <p>In our years of experience in working with clients across domains, we see that it takes not just data and technology skills, but a certain kind of team to get things done and deliver results. That’s why we are focused on our people all the time. If you are a curious and motivated self-starter looking to lose yourself in generous enthusiasm and cooperate with others for common ends, Acies will be your ideal career destination.</p>
          </section>
        </section>

        <section className="team-row">
          <div class="component">
            <div class="component__header">
              <h1>Meet our associates</h1>
            </div>
            <div class="component__corosal relative">
            <img src={`assets/Team/carousel/Left.svg`} alt="left" className="side-button" title="Move left" onClick={this.changeOrder.bind(this,this.state.activeReviewIndex - 1)}/>
              <div class="corosal__content">
                <h2>"{this.state.activeReview.title}"<br></br><span className="team-member-info"> {this.state.activeReview.name}, {this.state.activeReview.role}</span></h2>
                <p>
                   {this.state.activeReview.content}
                </p>
              </div>

              <img src={`assets/Team/carousel/Right.svg`} alt="Right"  className="side-button" title="Move right" onClick={this.changeOrder.bind(this,this.state.activeReviewIndex + 1)}/>
              <div class="box1"></div>
            </div>
        <div class="component__images">
        {this.state.reviews.length < 5 && this.state.reviews.map((review) => (          
                <img src={review.image} alt="" className={review.index === this.state.activeReviewIndex ? "circle active__circle" : "circle"} onClick={this.changeOrder.bind(this,review.index)}/>
              )              
              )}
        {this.state.reviews.length >= 5 && this.getReviews().map((review) => (
          <img src={review.image} alt="" className={review.index === this.state.activeReviewIndex ? "circle active__circle" : "circle"} onClick={this.changeOrder.bind(this,review.index)}/>
        ))}
        </div>
      </div>
        </section>
      
        <section className="team-row apply-here white-text padding-5">
          <h2>Want to be a part of an exciting team?</h2>
          <a href={"https://talent.aciesglobal.com/jobs/Careers"} target={"_blank"} rel="noreferrer" className="team-button white-text">Apply here</a>
        </section>
      </div>
    );
  }
}

export default Team;