import React, { Component } from 'react';
import './OurWork.css';
import case_studies from '../../contents/case_study.json'
import {Link} from 'react-router-dom';

const filter_keys = ["#Analytics", "#Enterprise Application", "#Cloud", "#Enterprise Mobility", "#Data Engineering", "#Digital Transformation", "View all"] 

class OurWork extends Component {
  constructor(props){
    super(props);
    this.state = {
      display_case_studies: case_studies,
      mobile_view: false
    };
  }
 
  clicked_list = []

  handle_screen_resize = (e) => 
    {
      this.setState({mobile_view: window.innerWidth<540})
    }

  filter(value)
  {
    // let index = this.clicked_list.indexOf(value)
    // if(index > -1) {
    //   this.clicked_list.splice(index, 1)
    // }
    // else this.clicked_list.push(value)

    if(this.clicked_list[0] === value) 
    {
      this.clicked_list.pop()
      this.setState({display_case_studies:case_studies})
    }
    else if(value === "View all")
    { 
      this.clicked_list[0] = value
      this.setState({display_case_studies:case_studies})
    } 
    else
    {
      this.clicked_list[0] = value
      let result = []
      for(let i=0;i<case_studies.length;i++)
      {   
         if(this.clicked_list.find(x => case_studies[i].keywords.includes(x)))
          { 
           result.push(case_studies[i])
          }
       }
      if(result.length > 0) this.setState({display_case_studies:result})
    } 
    
  }

  // componentWillMount(){}

  // componentWillReceiveProps(){}
  // shouldComponentUpdate(){}
  // componentWillUpdate(){}
  // componentDidUpdate(){}

  componentDidMount(){
    window.addEventListener('resize',this.handle_screen_resize);
  }
  componentWillUnmount(){
    window.removeEventListener('resize',this.handle_screen_resize);
  }

  render() {
    return (
      <div className="our-work">
        <header className="header-banner our-work-banner-1" style={{backgroundImage:this.state.mobile_view || window.innerWidth<540 ?"url(/assets/CaseStudy/banner-mobile.jpg)":"url(/assets/CaseStudy/banner.jpg)"}}>
              <h1>Case studies</h1>
        </header>
        <div className="filter">
              {filter_keys.map((item, index) => (
                <div key={index} onClick={()=>this.filter(item)} className={this.clicked_list.length > 0 && this.clicked_list[0] === item ? "filter-select" : ""}>{item}</div>
              ))}
        </div>
        <div className="grid-container">
            {this.state.display_case_studies.map((item,index)=>(
                <Link to={{pathname:item.path, content:{item}}} className="grid-item" key={index}>
                  <img className="grid-image" src={item.image} alt="" ></img>
                  <div className="grid-content">{item.heading}</div>
              </Link>
            ))}
        </div>
      </div>
    );
  }
}

export default OurWork;