import React, { Component } from 'react';
import './App.css';
import Navigation from './components/Navigation/Navigation';
import {BrowserRouter as Router, Redirect, Route, Switch} from 'react-router-dom';
import Home from './components/Home/Home';
import Services from './components/Services/Services';
import OurApproach from './components/OurApproach/OurApproach';
import AdaptiveSolutions from './components/AdaptiveSolutions/AdaptiveSolutions';
import Team from './components/Team/Team';
import OurWork from './components/OurWork/OurWork';
import ContactUs from './components/ContactUs/ContactUs';
import Footer from './components/Footer/Footer';
import ReachOutForm from './components/ReachOutForm/ReachOutForm';
import CaseStudy from './components/CaseStudy/CaseStudy';
import Service from './components/Services/Service';
import Insights from './components/Insights/Insights';
import ScrollToTop from './components/ScrollToTop';
import AboutUs from './components/AboutUs/AboutUs';



class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
 }

  render(){
  return (
    <Router>
        <ScrollToTop/>
        <Navigation/>
        <Switch>   
          <Route exact path='/home' component={Home}></Route>
          <Route exact path='/services/:service' component={Service}></Route>
          <Route exact path='/services' component={Services}></Route>
          <Route exact path='/our-approach' component={OurApproach}></Route>
          <Route exact path='/adaptive-solutions' component={AdaptiveSolutions}></Route>
          <Route exact path='/team' component={Team}></Route>
          <Route exact path='/case-studies' component={OurWork}></Route>
          <Route exact path='/case-studies/:id' component={CaseStudy}></Route>
          <Route exact path='/contact-us' component={ContactUs}></Route>
          <Route exact path='/insights' component={Insights}></Route>
          <Route exact path='/insights/:id' component={Insights}></Route>
          <Route exact path='/about-us' component={AboutUs}></Route>
          <Route exact path='/'><Redirect to="/home"/></Route>
          <Route exact path='*'><Redirect to="/home"/></Route>
        </Switch>
        <ReachOutForm/>
        <Footer/> 
    </Router>
  );
}
}

export default App;

