import React from 'react';
import './LeftImageRow.css';
const LeftImageRow = ({image, name, role, text}) => {
    return ( 
        <div className="LeftImageRow">
            <section className="left-image-row-left">
                <img src={image} alt={name} />
            </section>
            <section className="left-image-row-right">
                <h3>{name}</h3>
                <h5>{role}</h5>
                <p>{text}</p>
            </section>
        </div>
     );
}
 
export default LeftImageRow;