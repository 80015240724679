function random_pick(input_length, random_count) 
{
    var arr=[]
    while(arr.length < random_count) 
    {
        var randomNumber = Math.random();
        var ind  = Math.floor(randomNumber * input_length);
        if(!arr.includes(ind))
        {
            arr.push(ind)
        }
    }
    return arr;
}

export default random_pick;